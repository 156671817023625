import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from 'react-hook-form';
import { faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { faBell, faPassport } from "@fortawesome/free-solid-svg-icons";
import { InputField, SignUpText, InfoTextBlock } from "../helpers/formHelpers";

import "../components/Login.css";

// import api from "../services/api";

function Login({ show, handleClose }) {
  const [isMember, setIsMember] = useState(false);


  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();

  const password = watch('password');
  const confirmPassword = watch('confirmPassword');

  const toggleMember = (isUpdate) => {
    setIsMember(isUpdate);
    reset();
  };

  const onSubmit = (data) => {
    console.log(data);
    // api.get('/endpoint')  
    //   .then((response) => {
    //     setData(response.data);
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setError(err);
    //     setLoading(false);
    //   });
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`modal-lg ${isMember ? 'modal-signup-btn' : 'modal-md modal-login'}`}
      show={show}
      onHide={handleClose}
    >
      {isMember ? (
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6 sign-up-form">
                <div className="form-body">
                  <InputField
                    id="fName"
                    label="First Name"
                    register={register}
                    errors={errors}
                    validation={{ required: 'First Name is required' }}
                  />
                  <InputField
                    id="lName"
                    label="Last Name"
                    register={register}
                    errors={errors}
                    validation={{ required: 'Last Name is required' }}
                  />
                  <InputField
                    id="email"
                    label="Email address"
                    register={register}
                    errors={errors}
                    validation={{
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address',
                      },
                    }}
                  />
                  <InputField
                    id="tel"
                    label="Phone Number"
                    type="tel"
                    register={register}
                    errors={errors}
                    validation={{ required: 'Phone number is required' }}
                  />
                  <InputField
                    id="password"
                    label="Password"
                    type="password"
                    register={register}
                    errors={errors}
                    autocomplete="current-password"
                    validation={{
                      required: 'Password is required',
                      minLength: { value: 6, message: 'Password must be at least 6 characters long' },
                    }}
                  />
                  <InputField
                    id="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    register={register}
                    errors={errors}
                    autocomplete="new-password"
                    validation={{
                      required: 'Please confirm your password',
                      validate: (value) =>
                        value === password || 'Passwords do not match',
                    }}
                  />
                  {!errors.confirmPassword && confirmPassword && confirmPassword === password && (
                    <p style={{ color: 'green' }}>Passwords match</p>
                  )}
                  <div className="form-check mb-3">
                    <input className="form-check-input" type="checkbox" id="termsCheck" />
                    <label className="form-check-label" htmlFor="termsCheck">
                      By signing up you accept our <a href="#">Terms</a> and <a href="#">Privacy Policy</a>.
                    </label>
                  </div>
                  <button type="submit" className="btn btn-primary btn-signup w-100 mb-3">
                    Sign Up
                  </button>
                </div>
              </div>
              <div className="col-md-6 sign-up-text">
                <div className="txt-main">
                  <div className="text-body">
                    <h3>Sign up with Click2Visa</h3>
                  </div>
                  <SignUpText isMember={isMember} toggleMember={toggleMember} />
                  <InfoTextBlock
                    icon={faBell}
                    title="Faster Visa Filling"
                    description="Sign up into click2visa sign up into click2visa"
                  />
                  <InfoTextBlock
                    icon={faCcVisa}
                    title="Faster Visa Filling"
                    description="Sign up into click2visa sign up into click2visa Sign up into click2visa"
                  />
                  <InfoTextBlock
                    icon={faPassport}
                    title="Faster Visa Filling"
                    description="Sign up into click2visa sign up into click2visa Sign up"
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="text-body">
                <h3>Sign in with Click2Visa</h3>
              </div>
              <SignUpText isMember={isMember} toggleMember={toggleMember} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-body">
                    <InputField
                      id="email"
                      label="Email address"
                      type="email"
                      register={register}
                      errors={errors}
                      validation={{
                        required: 'Email is required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Invalid email address',
                        },
                      }}
                    />
                    <InputField
                      id="password"
                      label="Password"
                      type="password"
                      register={register}
                      autocomplete="new-password"
                      errors={errors}
                      validation={{ required: 'Password is required' }}
                    />
                    <div className="forgotpass">
                      <a href="#">Forgot your password?</a>
                    </div>
                    <button type="submit" className="btn btn-primary btn-signup w-100 mb-3">
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
}

export default Login;
