// src/App.js
import React from 'react';
import RouterApp from './router';

function App() {
  return (
    <RouterApp />
  );
}

export default App;
