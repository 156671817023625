import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const InputField = ({ id, label, type = "text", register, errors, validation }) => (
  <div className="mb-3 form-floating">
    <input
      className="form-control"
      id={id}
      type={type}
      placeholder={label}
      {...register(id, validation)}
    />
    <label htmlFor={id}>{label}</label>
    {errors[id] && <p style={{ color: "red" }}>{errors[id]?.message}</p>}
  </div>
);

export const ToggleButton = ({ isMember, toggleMember }) => (
  <button className="togglebtn" type="button" onClick={() => toggleMember(!isMember)}>
    {isMember ? "Login" : "Register"}
  </button>
);

export const SignUpText = ({ isMember, toggleMember }) => (
  <div className="already-signup">
    <p>
      {isMember ? "Have an account already?" : "Don't have an account? "}
      <ToggleButton isMember={isMember} toggleMember={toggleMember} />
    </p>
  </div>
);

export const InfoTextBlock = ({ icon, title, description }) => (
  <div className="textonicon">
    <FontAwesomeIcon icon={icon} />
    <div>
      <h6>{title}</h6>
      <p>{description}</p>
    </div>
  </div>
);

