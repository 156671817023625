

import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
// import { Contact } from '../components/Contact';
import Home from '../containers/Home';

// const Login = lazy(() => import('../containers/Login'));




const AppRoutes = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route path="/" element={<Home />} />
                {/* <Route path="/contact" element={<ProtectedRoute element={<Contact />} />} /> */}
                {/* <Route path="*" element={<NotFound />} /> */}
            </Routes>
        </Suspense>

    )
}

export default AppRoutes