import React, { useState } from "react";
import "../App.css";
import google from "../assets/images/google.png";
import facebook from "../assets/images/facebook.png";
import {
  faFacebookF,
  faLinkedinIn,
  faSquareInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faPhone,
  faRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Login from "../components/Login";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [ismember, setIsmember] = useState(true);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  const togglemem = () => {
    setIsmember({ ismember: !ismember })
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <header>
      <div className="header-top">
        <div className="container">
          <div className="top-main">
            <div className="social-media">
              <ul>
                <li>
                  <FontAwesomeIcon icon={faFacebookF} />
                </li>
                <li>
                  <FontAwesomeIcon icon={faSquareInstagram} />
                </li>
                <li>
                  <FontAwesomeIcon icon={faXTwitter} />
                </li>
                <li>
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </li>
              </ul>
            </div>
            <div className="email-phone">
              <ul>
                <li>
                  <FontAwesomeIcon icon={faEnvelope} /> info@click2visa.com
                </li>
                <li>
                  <FontAwesomeIcon icon={faPhone} /> +91 9666648446
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container">
            <a className="navbar-brand" href="#">
              Logo
            </a>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleMenu}
              aria-controls="navbarNav"
              aria-expanded={isMenuOpen ? "true" : "false"}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${isMenuOpen ? "show animate__animated animate__fadeInDown" : ""
                }`}
              id="navbarNav"
            >
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <a className="nav-link" href="#home">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#home">
                    About
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown1"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Visas
                  </a>
                  <ul
                    className="dropdown-menu animate__animated animate__fadeInDown"
                    aria-labelledby="navbarDropdown1"
                  >
                    <li>
                      <a className="dropdown-item" href="#action1">
                        Action 1
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#action2">
                        Action 2
                      </a>
                    </li>
                    <li className="dropdown-submenu">
                      <a className="dropdown-item dropdown-toggle" href="#">
                        Submenu 1
                      </a>
                      <ul className="dropdown-menu animate__animated animate__fadeInRight">
                        <li>
                          <a className="dropdown-item" href="#subaction1">
                            Sub Action 1
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#subaction2">
                            Sub Action 2
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown2"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    E-Visa
                  </a>
                  <ul
                    className="dropdown-menu animate__animated animate__fadeInDown"
                    aria-labelledby="navbarDropdown2"
                  >
                    <li>
                      <a className="dropdown-item" href="#action4">
                        Action 4
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#action5">
                        Action 5
                      </a>
                    </li>
                    <li className="dropdown-submenu">
                      <a className="dropdown-item dropdown-toggle" href="#">
                        Submenu 2
                      </a>
                      <ul className="dropdown-menu animate__animated animate__fadeInRight">
                        <li>
                          <a className="dropdown-item" href="#subaction3">
                            Sub Action 3
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#subaction4">
                            Sub Action 4
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown2"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    OCI
                  </a>
                  <ul
                    className="dropdown-menu animate__animated animate__fadeInDown"
                    aria-labelledby="navbarDropdown2"
                  >
                    <li>
                      <a className="dropdown-item" href="#action4">
                        Action 4
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#action5">
                        Action 5
                      </a>
                    </li>
                    <li className="dropdown-submenu">
                      <a className="dropdown-item dropdown-toggle" href="#">
                        Submenu 2
                      </a>
                      <ul className="dropdown-menu animate__animated animate__fadeInRight">
                        <li>
                          <a className="dropdown-item" href="#subaction3">
                            Sub Action 3
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#subaction4">
                            Sub Action 4
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Passport
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Renunciation
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Miscellaneous
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="#contact">
                    Contact
                  </a>
                </li>
                <li className="nav-item">
                  <Button
                    className="sign-in"
                    variant="primary"
                    onClick={handleShow}
                  >
                    <FontAwesomeIcon icon={faRightToBracket} /> Sign In
                  </Button>
                  {show && <Login show={show} handleClose={handleClose} />}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
